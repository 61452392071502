<template>
    <main class="user_rounds-page">
        <section>
            <div class="container py-5">
                <mega-card>
                    <div class="card-media ratio" :style="{paddingBottom: padding+'%'}">
                        <gmap-map class="fill-parent"
                                  :center="center"
                                  :zoom="14"
                                  map-type-id="terrain"
                                  :options="{
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    gestureHandling: 'cooperative',
                                    rotateControl: false,
                                    fullscreenControl: false
                                  }"
                                  style="position: absolute;">

                            <gmap-marker v-for="(round, i) in rounds" :key="i"
                                         :clickable="true"
                                         :icon="icon(round)"
                                         @click="$router.push('/round/'+round.idt_round)"
                                         :position="{lat: round.lat, lng: round.lng}"/>
                        </gmap-map>
                    </div>
                </mega-card>
            </div>
        </section>

        <section>
            <div class="container">

                <div class="d-flex">
                    <h4 class="bold text-muted text-dark">All User Rounds</h4>
                </div>

                <div class="row">
                    <div class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                         v-for="(round, i) in rounds"
                         :ref="'col-'+round.idt_round"
                         :key="i">
                        <round-card :data="round"/>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
  import RoundCard from '../Rounds/components/round-card'

  export default {
    name: 'UserRoundsPage',
    props: ['userId'],
    data() {
      return {
        ratio: '16x9',
        rounds: [],
        slider: {
          slidesPerView: 3,
          breakpoints: {
            750: {
              slidesPerView: 1
            },
            990: {
              slidesPerView: 2
            },
            1100: {
              slidesPerView: 1
            },
            1470: {
              slidesPerView: 2
            }
          }
        },
        center: {
          lat: 59.9598743,
          lng: 30.2965115
        },
        user: {
          loaded: false
        }
      }
    },
    computed: {
      padding() {
        let r = this.ratio ? this.ratio.split('x') : [4, 3];

        return (r[1] / r[0]) * 100;
      }
    },
    mounted() {

      this.$navbar.name = 'User #'+this.userId+' Rounds';

      this.getUser();
      this.getRounds();
    },
    methods: {

      icon(round) {
        return {
          scaledSize: new window['google'].maps.Size(39.38, 70),
          origin: new window['google'].maps.Point(0, 0),
          anchor: new window['google'].maps.Point(0, 0),
          url: round['url_preview']
        }
      },

      getUser() {

        this.$api.v1.get('/user', {params: {id: this.userId}})
          .then(response => {
            this.user = response.data.user;

            if(this.user['idc_sex'] === 1) this.user.sex = 'Not set';
            if(this.user['idc_sex'] === 2) this.user.sex = 'Female';
            if(this.user['idc_sex'] === 3) this.user.sex = 'Male';

            this.$navbar.name = 'User #'+this.userId+' Rounds | '+this.user.name;
          })
          .catch(() => {
            this.$router.replace('/404')
          });
      },

      getRounds() {

        this.$api.v2.get('/rounds', {params: {idt_user: this.userId,limit: 500}})
          .then(response => {
            this.rounds = response.data.rounds;
          });
      }
    },
    components: {
      RoundCard
    }
  }
</script>
