var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "user_rounds-page" }, [
    _c("section", [
      _c(
        "div",
        { staticClass: "container py-5" },
        [
          _c("mega-card", [
            _c(
              "div",
              {
                staticClass: "card-media ratio",
                style: { paddingBottom: _vm.padding + "%" }
              },
              [
                _c(
                  "gmap-map",
                  {
                    staticClass: "fill-parent",
                    staticStyle: { position: "absolute" },
                    attrs: {
                      center: _vm.center,
                      zoom: 14,
                      "map-type-id": "terrain",
                      options: {
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        gestureHandling: "cooperative",
                        rotateControl: false,
                        fullscreenControl: false
                      }
                    }
                  },
                  _vm._l(_vm.rounds, function(round, i) {
                    return _c("gmap-marker", {
                      key: i,
                      attrs: {
                        clickable: true,
                        icon: _vm.icon(round),
                        position: { lat: round.lat, lng: round.lng }
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/round/" + round.idt_round)
                        }
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("section", [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.rounds, function(round, i) {
            return _c(
              "div",
              {
                key: i,
                ref: "col-" + round.idt_round,
                refInFor: true,
                staticClass:
                  "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
              },
              [_c("round-card", { attrs: { data: round } })],
              1
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("h4", { staticClass: "bold text-muted text-dark" }, [
        _vm._v("All User Rounds")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }